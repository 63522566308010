@import "./login";

.PageAuth {
    background: $dark;
    width: 100%;
    min-height: 100vh;
    padding: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
    position: relative;

    .background {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: contain;
        padding: 30px;
        max-width: 1000px;
        opacity: 0.02;
        z-index: 1;
    }

    .head {
        text-align: center;
    }

    form {
        position: relative;
        z-index: 10;
        max-width: 100%;
        width: 400px;
        user-select: none;

        img.logo {
            height: 60px;
            margin-bottom: 55px;
        }

        .title {
            color: $white;
            font-size: 1.5em;
            margin-bottom: 35px;
            font-weight: 300;
            line-height: 1.6em;
        }

        .Button {
            width: 100%;
            margin-top: 10px;
            margin-bottom: 50px;
        }

        .ctas {
            text-align: center;

            .item {
                font-weight: 300;
                margin-bottom: 15px;

                a {
                    margin-left: 5px;
                }
            }
        }

        input {
            background: transparent !important;
        }
    }

    &.register {
        form {
            width: 500px;
        }
    }
}

.Mobile {
    .PageAuth {
        padding: 30px;
        width: 100%;

        form {
            img.logo {
                height: 60px;
                margin-bottom: 25px;
            }

            .title {
                margin-bottom: 20px;
            }
        }
    }
}
