.role__permission {
  &--item {
    // box-shadow: 0 0 10px 8px #13203c;
    background: rgba(0, 0, 0, 0.24);
    box-shadow: 0 25px 20px -20px rgba(0, 0, 0, 0.1), 0 0 15px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    padding: 12px 0;
  }

  &.row {
    margin: 0 !important;
  }
}
