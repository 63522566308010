@import "../../../module.variables.scss";
.SupplierPage {
    .button-container {
        display: flex;
        justify-content: space-between;
    }
    table {
        tbody {
            .supplier {
                display: flex;
                align-items: center;
                &.inactive {
                    opacity: 0.25;
                    .supplier__image {
                        cursor: default;
                    }
                }
                .supplier__image {
                    margin-right: 16px;
                    width: 127px;
                    height: 54px;
                    object-fit: fill;
                    // border-radius: 50%;
                    // -webkit-border-radius: 50%;
                    // -moz-border-radius: 50%;
                    // -ms-border-radius: 50%;
                    // -o-border-radius: 50%;
                    // border: 1px solid;
                    background: #f6f6f6;
                    cursor: pointer;
                }
            }
            .supplier-code {
                &.inactive {
                    opacity: 0.25;
                }
            }
            .created-date {
                &.inactive {
                    opacity: 0.25;
                }
            }
            .action {
                &.inactive {
                    opacity: 0.25;
                }
                &:not(.inactive) {
                    .action--up {
                        cursor: pointer;
                        &:hover {
                            filter: drop-shadow(0 0 8px #7759de);
                            -webkit-filter: drop-shadow(0 0 8px #7759de);
                            svg {
                                transition: 0.5s;
                                -webkit-transition: 0.5s;
                                -moz-transition: 0.5s;
                                -ms-transition: 0.5s;
                                -o-transition: 0.5s;
                                transform: scale(1.25);
                                -webkit-transform: scale(1.25);
                                -moz-transform: scale(1.25);
                                -ms-transform: scale(1.25);
                                -o-transform: scale(1.25);
                            }
                        }
                    }
                    .action--down {
                        cursor: pointer;
                        &:hover {
                            filter: drop-shadow(0 0 8px #0466c8);
                            -webkit-filter: drop-shadow(0 0 8px #0466c8);
                            svg {
                                transition: 0.5s;
                                -webkit-transition: 0.5s;
                                -moz-transition: 0.5s;
                                -ms-transition: 0.5s;
                                -o-transition: 0.5s;
                                transform: scale(1.25);
                                -webkit-transform: scale(1.25);
                                -moz-transform: scale(1.25);
                                -ms-transform: scale(1.25);
                                -o-transform: scale(1.25);
                            }
                        }
                    }
                }
            }
            .edit {
                display: flex;
                transform: scale(1.5);
                -webkit-transform: scale(1.5);
                -moz-transform: scale(1.5);
                -ms-transform: scale(1.5);
                -o-transform: scale(1.5);
                cursor: pointer;
                &.inactive {
                    opacity: 0.25;
                    cursor: default;
                }
                &:not(.inactive):hover {
                    svg {
                        transition: 0.5s;
                        -webkit-transition: 0.5s;
                        -moz-transition: 0.5s;
                        -ms-transition: 0.5s;
                        -o-transition: 0.5s;
                        transform: scale(1.2);
                        -webkit-transform: scale(1.2);
                        -moz-transform: scale(1.2);
                        -ms-transform: scale(1.2);
                        -o-transform: scale(1.2);
                    }
                }
            }
        }
    }
}