@import "../../../module.variables.scss";

.Table {
  .Table__Body {
    .title {
      color: #4680ff;
    }
    .content {
      max-width: 20vw;
      white-space: pre-wrap;
      span {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        * {
          display: none;
        }
        *:first-child,
        *:nth-child(2) {
          display: block;
        }
      }
    }
    .image {
      height: 48px;
    }
    .status {
      font-size: 14px;
      padding: 5px 8px;
      border-radius: 24px;
      color: #ddd;
      background: rgba(221, 221, 221, 0.15);
      border: 1px solid rgba(221, 221, 221, 0.25);
      &.status--active {
        color: #56ca00;
        background: rgba(86, 202, 0, 0.15);
        border: 1px solid rgba(86, 202, 0, 0.25);
      }
      &.status--expired {
        color: #ff4c51;
        background: rgba(255, 76, 81, 0.15);
        border: 1px solid rgba(255, 76, 81, 0.25);
      }
    }
    .action {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      .action__edit {
        cursor: pointer;
        transition: 0.25s ease-out;
        -webkit-transition: 0.25s ease-out;
        -moz-transition: 0.25s ease-out;
        -ms-transition: 0.25s ease-out;
        -o-transition: 0.25s ease-out;
        &:hover {
          transform: scale(1.1);
          -webkit-transform: scale(1.1);
          -moz-transform: scale(1.1);
          -ms-transform: scale(1.1);
          -o-transform: scale(1.1);
        }
        &.action__edit--expired {
          opacity: 0.25;
          cursor: default;
        }
      }
    }
  }
}

.Edit {
  min-width: 400px;
  .title,
  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }
  .group {
    display: flex;
    justify-content: space-between;
  }
  .item,
  .editor {
    margin-bottom: 18px;
    width: 100%;
  }
  .label {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    // margin-bottom: 8px;
  }
  .input {
    .react-datetime-picker__wrapper {
      border: 1px solid $input-border-color;
      border-radius: $borderRadius;
      background: $input-background-color;
      -webkit-border-radius: $borderRadius;
      -moz-border-radius: $borderRadius;
      -ms-border-radius: $borderRadius;
      -o-border-radius: $borderRadius;
    }
  }

  // .btnBan {
  //     background-color: #d99e0b;
  //     border: 1px solid #d99e0b;
  // }
}
