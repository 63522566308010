@import "../../../module.variables.scss";

.Settlement {
  .TableFilterInputSelect {
    min-width: 200px;
  }
  .Table {
    overflow: auto;
    .Table__Body {
      .device-id {
        color: #4680ff;
      }
      .status {
        font-size: 14px;
        padding: 6px 10px;
        border-radius: 24px;
        color: rgba(244, 244, 245, 0.9);

        &.status--just_registered {
          // color: #4680ff;
          background: $status-grey;
        }
        &.status--waiting_confirm {
          // color: #4680ff;
          background: $status-orange;
        }
        &.status--waiting_approve {
          // color: #4680ff;
          background: $status-yellow;
        }
        &.status--processing {
          // color: #4680ff;
          background: $status-blue;
        }
        &.status--reject {
          // color: #4680ff;
          background: $status-red;
        }
        &.status--success {
          // color: #11c15b;
          background: $status-green;
        }
      }
      .type {
        font-size: 14px;
        padding: 6px 10px;
        border-radius: 24px;
        color: rgba(244, 244, 245, 0.9);
        &--deposit {
          // color: #56ca00;
          background: $status-blue;
          // border: 1px solid rgba(86, 202, 0, 0.25);
          // border: 1px solid #56ca00;
        }
        &--withdraw {
          // color: #ff4c51;
          background: $status-orange;
          // border: 1px solid rgba(255, 76, 81, 0.25);
          // border: 1px solid #ff4c51;
        }
      }
      .coin {
        display: flex;
        align-items: center;
        .coin-label {
          font-weight: 700;
        }
        .coin-avatar {
          width: 24px;
          height: 24px;
          margin-right: 8px;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          -o-border-radius: 50%;
        }
      }
    }
  }
  .copy {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    color: #4680ff;

    svg {
      width: 20px;
      height: 20px;

      path {
        fill: #fff;
      }
    }
  }
  .general-popup-compnt {
    .InputWraper {
      .label {
        color: #000;
      }
      .wraper {
        .input {
          textarea {
            border: 1px solid rgba(70, 128, 255, 0.5);
            color: #000;
            background: #edf3ff;
            transition: 0.25s;
            &:focus,
            &:hover {
              border: 1px solid rgba(70, 128, 255, 1);
            }
          }
        }
      }
    }
  }
  .Set-rate {
    .box {
      min-width: 400px;
    }

    .item {
      margin-bottom: 18px;
      .label {
        color: #fff;
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
}
