@import "../../../module.variables.scss";
.TransactionList {
  .Table {
    .Table__Body {
      .coin {
        display: flex;
        align-items: center;
        .coin-label {
          font-weight: 700;
        }
        .coin-avatar {
          width: 24px;
          height: 24px;
          margin-right: 8px;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          -o-border-radius: 50%;
        }
      }
      .email {
        color: #4680ff;
      }
    }
  }
}
