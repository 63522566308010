@import "../../../module.variables.scss";
.PageFranchiseTable {
  .Table {
    .Table__Body {
      tr {
        td {
          .user {
            // &:hover {
            //     text-decoration: underline;
            // }
            .user-clickable {
              cursor: pointer;
              color: #16b1ff;
              text-decoration: underline;
            }
          }
          .recovery {
            cursor: pointer;
            color: #ff4c51;
            text-decoration: underline;
          }
          .profit-volume-green {
            background: rgba(86, 202, 0, 0.05);
            color: #56ca00;
          }
          .profit-volume-red {
            background: rgba(255, 76, 81, 0.05);
            color: #ff4c51;
          }
          .level {
            max-width: 80px;
            width: 100%;
          }
        }
      }
      .action {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 30px;
        .action__plus {
          cursor: pointer;
          transition: 0.25s ease-out;
          -webkit-transition: 0.25s ease-out;
          -moz-transition: 0.25s ease-out;
          -ms-transition: 0.25s ease-out;
          -o-transition: 0.25s ease-out;
          svg {
            width: 20px;
            height: 20px;
            path {
              fill: #adb7be;
            }
          }
          &:hover {
            transform: scale(1.2);
            -webkit-transform: scale(1.2);
            -moz-transform: scale(1.2);
            -ms-transform: scale(1.2);
            -o-transform: scale(1.2);
          }
        }
        .action__minus {
          cursor: pointer;
          transition: 0.25s ease-out;
          -webkit-transition: 0.25s ease-out;
          -moz-transition: 0.25s ease-out;
          -ms-transition: 0.25s ease-out;
          -o-transition: 0.25s ease-out;
          svg {
            width: 20px;
            height: 20px;
            path {
              fill: #adb7be;
            }
          }
          &:hover {
            transform: scale(1.2);
            -webkit-transform: scale(1.2);
            -moz-transform: scale(1.2);
            -ms-transform: scale(1.2);
            -o-transform: scale(1.2);
          }
        }
      }
    }
  }
  .recovery-popup {
    .box {
      max-width: 600px;
      width: 100%;
    }
    .recovery-content {
      .recovery-content__item {
        display: flex;
        margin-bottom: 8px;
        .recovery-content__item__label {
          font-size: 16px;
          color: #fff;
          white-space: nowrap;
        }
        .recovery-content__item__value {
          word-break: break-all;
          font-size: 16px;
        }
      }
    }
  }
}
