.game-page {
    .button-container {
        display: flex;
        justify-content: space-between;
    }
    .Table {
        .Table__Body {
            .game {
                display: flex;
                align-items: center;
                &.inactive {
                    opacity: 0.25;
                    .game__image {
                        cursor: default;
                    }
                }
                .game__image {
                    margin-right: 16px;
                    width: 56px;
                    height: 56px;
                    border-radius: 50%;
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    -ms-border-radius: 50%;
                    -o-border-radius: 50%;
                    border: 3px solid;
                    cursor: pointer;
                }
            }
            .supplier {
                &.inactive {
                    opacity: 0.25;
                }
            }
            .game-category {
                &.inactive {
                    opacity: 0.25;
                }
            }
            .table-name {
                &.inactive {
                    opacity: 0.25;
                }
            }
            .created-date {
                &.inactive {
                    opacity: 0.25;
                }
            }
            .action {
                &.inactive {
                    opacity: 0.25;
                }
                &:not(.inactive) {
                    .action--up {
                        cursor: pointer;
                        &:hover {
                            filter: drop-shadow(0 0 8px #7759de);
                            -webkit-filter: drop-shadow(0 0 8px #7759de);
                            svg {
                                transition: 0.5s;
                                -webkit-transition: 0.5s;
                                -moz-transition: 0.5s;
                                -ms-transition: 0.5s;
                                -o-transition: 0.5s;
                                transform: scale(1.25);
                                -webkit-transform: scale(1.25);
                                -moz-transform: scale(1.25);
                                -ms-transform: scale(1.25);
                                -o-transform: scale(1.25);
                            }
                        }
                    }
                    .action--down {
                        cursor: pointer;
                        &:hover {
                            filter: drop-shadow(0 0 8px #0466c8);
                            -webkit-filter: drop-shadow(0 0 8px #0466c8);
                            svg {
                                transition: 0.5s;
                                -webkit-transition: 0.5s;
                                -moz-transition: 0.5s;
                                -ms-transition: 0.5s;
                                -o-transition: 0.5s;
                                transform: scale(1.25);
                                -webkit-transform: scale(1.25);
                                -moz-transform: scale(1.25);
                                -ms-transform: scale(1.25);
                                -o-transform: scale(1.25);
                            }
                        }
                    }
                }
            }
            .edit {
                display: flex;
                transform: scale(1.5);
                -webkit-transform: scale(1.5);
                -moz-transform: scale(1.5);
                -ms-transform: scale(1.5);
                -o-transform: scale(1.5);
                cursor: pointer;
                &.inactive {
                    opacity: 0.25;
                    cursor: default;
                }
                &:not(.inactive):hover {
                    svg {
                        transition: 0.5s;
                        -webkit-transition: 0.5s;
                        -moz-transition: 0.5s;
                        -ms-transition: 0.5s;
                        -o-transition: 0.5s;
                        transform: scale(1.2);
                        -webkit-transform: scale(1.2);
                        -moz-transform: scale(1.2);
                        -ms-transform: scale(1.2);
                        -o-transform: scale(1.2);
                    }
                }
            }
        }
    }
}