@import "../../../module.variables.scss";
.UserList {
    table {
        tbody {
            .email {
                color: #4680FF;
            }
            .status {
                padding: 6px 10px;
                border-radius: 24px;
                -webkit-border-radius: 24px;
                -moz-border-radius: 24px;
                -ms-border-radius: 24px;
                -o-border-radius: 24px;
                color: rgba(244,244,245,.9);
                &.status--just_registered {
                    // color: #4680ff;
                    background: $status-grey;
                }
                &.status--email_verified, &.status--pass {
                    // color: #11c15b;
                    background: $status-green;
                }
            }
            .action {
                display: flex;
                .action__edit {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 8px;
                    cursor: pointer;
                    transition: 0.25s ease-out;
                    -webkit-transition: 0.25s ease-out;
                    -moz-transition: 0.25s ease-out;
                    -ms-transition: 0.25s ease-out;
                    -o-transition: 0.25s ease-out;
                    &:hover {
                        transform: scale(1.2);
                        -webkit-transform: scale(1.2);
                        -moz-transform: scale(1.2);
                        -ms-transform: scale(1.2);
                        -o-transform: scale(1.2);
                    }
                }
                .action__more {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    transition: 0.25s ease-out;
                    -webkit-transition: 0.25s ease-out;
                    -moz-transition: 0.25s ease-out;
                    -ms-transition: 0.25s ease-out;
                    -o-transition: 0.25s ease-out;
                    &:hover {
                        transform: scale(1.2);
                        -webkit-transform: scale(1.2);
                        -moz-transform: scale(1.2);
                        -ms-transform: scale(1.2);
                        -o-transform: scale(1.2);
                    }
                }
            }
        }
    }
}