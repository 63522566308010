@import "../../../module.variables.scss";
.franchise-commission-history {
    .button-container {
        display: flex;
        justify-content: space-between;
        .button-group {
            .approve-button {
                margin-right: 16px;
            }
        }
        .infor-container {
            margin-bottom: 15px;
            display: flex;
            .total-amount {
                display: flex;
                align-items: center;
                .total-amount__icon {
                    border-radius: 50%;
                    background: rgba(#f3ba2f, 0.25);
                    width: 36px;
                    height: 36px;
                    margin-right: 12px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .total-amount__content {
                    color: #d0d0d0;
                    font-size: 28px;
                    display: flex;
                    .sub-text {
                        white-space: nowrap;
                        color: #f3ba2f;
                        font-size: 15px;
                        transform: translateY(5px);
                        -webkit-transform: translateY(5px);
                        -moz-transform: translateY(5px);
                        -ms-transform: translateY(5px);
                        -o-transform: translateY(5px);
                    }
                }
            }
        }
    }
    .Table {
        .Table__Body {
            .value {
                white-space: nowrap;
                .email {
                    color: #4680FF;
                }
            }
            .level {
                width: 64px;
                height: 64px;
                max-width: initial;
            }
            .value .icon svg {
                height: 15px;
                padding-left: 5px;
                cursor: pointer;
                * {
                    fill: #4680ff;
                }
            }
        }
    }
}