@import "../../../../module.variables.scss";

.DepositDIG {
  .TableFilterInputSelect {
    min-width: 200px;
  }
  .Table {
    overflow: auto;
    .Table__Body {
      .device-id {
        color: #4680ff;
      }
      .status {
        font-size: 14px;
        padding: 6px 10px;
        border-radius: 24px;
        color: rgba(244, 244, 245, 0.9);
        &--pending {
          // color: #ff9d00;
          background: $status-yellow;
          // border: 1px solid rgba(255, 157, 0, 0.25);
          // border: 1px solid #ff9d00;
        }
        &--success {
          // color: #56ca00;
          background: $status-green;
          // border: 1px solid rgba(86, 202, 0, 0.25);
          // border: 1px solid #56ca00;
        }
        &--reject {
          // color: #ff4c51;
          background: $status-red;
          // border: 1px solid rgba(255, 76, 81, 0.25);
          // border: 1px solid #ff4c51;
        }
      }
      .type {
        font-size: 14px;
        padding: 6px 10px;
        border-radius: 24px;
        color: rgba(244, 244, 245, 0.9);
        &--deposit {
          // color: #56ca00;
          background: $status-blue;
          // border: 1px solid rgba(86, 202, 0, 0.25);
          // border: 1px solid #56ca00;
        }
        &--withdraw {
          // color: #ff4c51;
          background: $status-orange;
          // border: 1px solid rgba(255, 76, 81, 0.25);
          // border: 1px solid #ff4c51;
        }
      }
      .qr {
        min-width: 150px;
      }
    }
  }
  .copy {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    color: #4680ff;

    svg {
      width: 20px;
      height: 20px;

      path {
        fill: #fff;
      }
    }
  }
  .general-popup-compnt {
    .InputWraper {
      .label {
        color: #000;
      }
      .wraper {
        .input {
          textarea {
            border: 1px solid rgba(70, 128, 255, 0.5);
            color: #000;
            background: #edf3ff;
            transition: 0.25s;
            &:focus,
            &:hover {
              border: 1px solid rgba(70, 128, 255, 1);
            }
          }
        }
      }
    }
  }
}
