@import "../../module.variables.scss";

.FiatList {
  .Table {
    .Table__Body {
      .coin {
        display: flex;
        align-items: center;
        .coin-label {
          font-weight: 700;
        }
        .coin-avatar {
          width: 24px;
          height: 24px;
          margin-right: 16px;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          -o-border-radius: 50%;
        }
      }
      .email {
        color: #4680ff;
      }
      .status {
        font-size: 14px;
        padding: 6px 10px;
        border-radius: 24px;
        color: rgba(244, 244, 245, 0.9);
        white-space: nowrap;
        &--just_created {
          // color: #ff9d00;
          background: $status-yellow;
          // border: 1px solid rgba(255, 157, 0, 0.25);
          // border: 1px solid #ff9d00;
        }
        &--success {
          // color: #56ca00;
          background: $status-green;
          // border: 1px solid rgba(86, 202, 0, 0.25);
          // border: 1px solid #56ca00;
        }
        &--failed {
          // color: #ff4c51;
          background: $status-red;
          // border: 1px solid rgba(255, 76, 81, 0.25);
          // border: 1px solid #ff4c51;
        }
        &--pending {
          // color: #0466c8;
          background: $status-blue;
          // border: 1px solid rgba(4, 102, 200, 0.25);
          // border: 1px solid #ff4c51;
        }
      }
      .fiat-action {
        display: flex;
      }
    }
  }
  .general-popup-compnt {
    .InputWraper {
      .label {
        color: #000;
      }
      .wraper {
        .input {
          textarea {
            border: 1px solid rgba(70, 128, 255, 0.5);
            color: #000;
            background: #edf3ff;
            transition: 0.25s;
            &:focus, &:hover {
              border: 1px solid rgba(70, 128, 255, 1);
            }
          }
        }
      }
    }
  }
}
