@import "../../../module.variables";
.page-auth-login {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    min-height: 100vh;
    height: 100%;
    background: -webkit-linear-gradient(top left, #190650, #5E0A82);
    @media (min-width: 992px) {
        align-items: center;
    }
    .login-form {
        width: 100%;
        padding: 40px;
        @media (min-width: 992px) {
            padding: 40px;
        }
        @media (min-width: 1200px) {
            padding: 100px;
        }
        .logo {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 24px;
        }
        .InputWraper {
            .label {
                margin-bottom: 12px;
                margin-left: 8px;
                font-size: 18px;
                color: rgba(255, 255, 255, 0.5);
            }
            .wraper {
                .input {
                    input {
                        border: none;
                        padding: 0 16px;
                        background: rgba(255, 255, 255, 0.1);
                        border-radius: 44px;
                        -webkit-border-radius: 44px;
                        -moz-border-radius: 44px;
                        -ms-border-radius: 44px;
                        -o-border-radius: 44px;
                        height: 44px;
                        font-size: 18px;
                    }
                }
                .errorMessage {
                    font-size: 12px;
                    margin-left: 8px;
                }
            }
        }
        .InputWraper:focus-within {
            .wraper {
                .input {
                    input {
                        box-shadow: 0px 0px 4px rgba(255, 255, 255, 1);
                    }
                }
            }
        }
        .InputWraper.error {
            .wraper {
                .input {
                    border-radius: 44px!important;
                    -webkit-border-radius: 44px!important;
                    -moz-border-radius: 44px!important;
                    -ms-border-radius: 44px!important;
                    -o-border-radius: 44px!important;
                }
            }
        }
        .Button.primary {
            background: rgba(255, 255, 255, 0.1);
            border: none;
            height: 44px;
            border-radius: 44px!important;
            -webkit-border-radius: 44px!important;
            -moz-border-radius: 44px!important;
            -ms-border-radius: 44px!important;
            -o-border-radius: 44px!important;
            margin-top: 16px;
            padding: 0 40px;
            color: rgba(255, 255, 255, 0.5);
            font-size: 16px;
            &:hover {
                background: #007bff;
                color: #fff;
            }
        }
    }
    .banner-image {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        margin-top: 40px;
        @media (min-width: 992px) {
            margin-top: initial;
        }
        .banner-image__img {
            width: 64%;
        }
    }
}