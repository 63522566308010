@import "../../../module.variables.scss";
.PageFranchiseLevelSettingHistory {
  .Table {
    .Table__Body {
      .created-by {
        color: #4680ff;
      }
      .user-applied {
        color: #4680ff;
      }
      .level {
        max-width: 80px;
        width: 100%;
      }
    }
  }
}
