@import "../../../../module.variables.scss";

.Edit {
  min-width: 400px;
  .group {
    display: flex;
    justify-content: space-between;
  }
  .item {
    margin-bottom: 18px;
    .label {
      color: #fff;
      font-size: 16px;
      font-weight: 400;
    }
  }
  // .btnBan {
  //     background-color: #d99e0b;
  //     border: 1px solid #d99e0b;
  // }
}
