@import '../../module.variables.scss';
.page-claim-list {
    .button-container {
        display: flex;
        justify-content: space-between;
        .button-group {
            .approve-button {
                margin-right: 16px;
            }
        }
    }
    .Table {
        .Table__Body {
            .email {
                color: #4680FF;
            }
            .proof-photo-1 {
                width: 100%;
                max-height: 36px;
                object-fit: contain;
                cursor: pointer;
            }
            .proof-photo-2 {
                width: 100%;
                max-height: 36px;
                object-fit: contain;
                cursor: pointer;
            }
            .status {
                font-size: 14px;
                padding: 5px 8px;
                border-radius: 24px;
                color: rgba(244,244,245,.9);
                &--pending {
                    // color: #ff9d00;
                    // border: 1px solid #ff9d00;
                    background: $status-yellow;
                }
                &--approved {
                    // color: #56ca00;
                    // border: 1px solid #56ca00;
                    background: $status-green;
                }
                &--reject {
                    // color: #ff4c51;
                    // border: 1px solid #ff4c51;
                    background: $status-red;
                }
            }
            .bot-status {
                font-size: 14px;
                padding: 5px 8px;
                border-radius: 24px;
                &--pending {
                    color: #ff9d00;
                    border: 1px solid #ff9d00;
                    // border: 1px solid #ff9d00;
                }
                &--success {
                    color: #56ca00;
                    border: 1px solid #56ca00;
                    // border: 1px solid #56ca00;
                }
                &--failure {
                    color: #ff4c51;
                    border: 1px solid #ff4c51;
                    // border: 1px solid #ff4c51;
                }
            }
            .action {
                display: flex;
                align-items: center;
                &.action--orders {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    transition: 0.25s ease-out;
                    -webkit-transition: 0.25s ease-out;
                    -moz-transition: 0.25s ease-out;
                    -ms-transition: 0.25s ease-out;
                    -o-transition: 0.25s ease-out;
                    &:hover {
                        transform: scale(1.2);
                        -webkit-transform: scale(1.2);
                        -moz-transform: scale(1.2);
                        -ms-transform: scale(1.2);
                        -o-transform: scale(1.2);
                    }
                }
            }
        }
    }
    //[BEGIN] - component
    .orders-popup-compnt {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.9);
        .orders {
            background: #0F192F;
            border-radius: 8px;
            padding: 32px 64px;
            max-width: 900px;
            width: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            -o-transform: translate(-50%, -50%);
            .indicator-close {
                cursor: pointer;
                position: absolute;
                top: 16px;
                right: 16px;
                width: 15px;
                height: 15px;
                padding: 12px;
                box-sizing: content-box;
                background: rgba(255, 255, 255, 0);
                border-radius: 50%;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                -ms-border-radius: 50%;
                -o-border-radius: 50%;
                transition: all .2s cubic-bezier(.4, 0, .6, 1);
                -webkit-transition: all .2s cubic-bezier(.4, 0, .6, 1);
                -moz-transition: all .2s cubic-bezier(.4, 0, .6, 1);
                -ms-transition: all .2s cubic-bezier(.4, 0, .6, 1);
                -o-transition: all .2s cubic-bezier(.4, 0, .6, 1);
                &:hover {
                    background: rgba(255, 255, 255, 0.1);
                }
            }
            .title {
                font-size: 20px;
                color: #fff;
                font-weight: 700;
                margin-bottom: 32px;
                text-align: center;
            }
            .Table {
                .Table__Body {
                    .status {
                        &.status--win {
                            color: #53e043;
                        }
                        &.status--lose {
                            color: #ff4340;
                        }
                        &.status--draw {
                            color: #ff9d00;
                        }
                    }
                }
            }
        }
    }
    //[END] - component
}