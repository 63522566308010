@import "../../../module.variables.scss";

.UserPromotion {
  .status {
    padding: 6px 10px;
    border-radius: 24px;
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    -ms-border-radius: 24px;
    -o-border-radius: 24px;
    color: rgba(244, 244, 245, 0.9);
    &.status--begin {
      // color: #4680ff;
      background: $status-cyan !important;
    }
    &.status--pass {
      // color: #11c15b;
      background: $status-green !important;
    }
  }

  .total-amount {
    display: flex;
    align-items: center;
    .total-amount__icon {
      border-radius: 50%;
      background: rgba(#f3ba2f, 0.25);
      width: 36px;
      height: 36px;
      margin-right: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .total-amount__content {
      color: #d0d0d0;
      font-size: 28px;
      display: flex;
      .sub-text {
        white-space: nowrap;
        color: #f3ba2f;
        font-size: 15px;
        transform: translateY(5px);
        -webkit-transform: translateY(5px);
        -moz-transform: translateY(5px);
        -ms-transform: translateY(5px);
        -o-transform: translateY(5px);
      }
    }
  }

  .config {
    margin-bottom: 10px;
    padding: 10px 15px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.24);
    box-shadow: 0 25px 20px -20px rgba(0, 0, 0, 0.1), 0 0 15px rgba(0, 0, 0, 0.06);

    .InputWraper {
      width: 25% !important;
    }
  }
}
