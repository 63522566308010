.general-popup-compnt {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
    .popup-wraper {
        position: absolute;
        max-width: 350px;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        background: #fff;
        box-shadow: 0px 0px 32px rgba(106, 112, 123, 0.12);
        border-radius: 16px;
        animation: showPopup ease-in 0.25s;
        -webkit-animation: showPopup ease-in 0.25s;
        .mt-32 {
            margin-top: 32px;
        }
        .btnClose {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            float: right;
            opacity: 0.8;
            margin: 20px 20px 0 0;
            // @media only screen and (max-width: $MobileWidthScreen) {
            //     margin: 16px 16px 0 0;
            // }
            svg {
                height: 12px;
                width: 12px;
                fill: #000;
            }
            &:hover {
                opacity: 1;
            }
        }
        .popup-body {
            padding: 48px 27px 40px 27px;
            text-align: center;
            .title-popup {
                font-size: 20px;
                font-weight: 600;
                color: #0A0B0D;
                margin-bottom: 8px;
            }
            .message-popup {
                // font-family: SFProDisplay;
                font-style: normal;
                font-weight: normal !important;
                font-size: 16px !important;
                line-height: 24px;
                text-align: center;
                color: #0A0B0D;
                margin-bottom: 16px;
                // @media screen and (max-width: $MobileWidthScreen) {
                //     font-size: 14px;
                // }
            }
            .button-container {
                display: flex;
                justify-content: space-evenly;
            }
            .pic-confirm {
                width: 219px;
                height: 112px;
                margin-bottom: 24px;
            }
        }
    }
}

@keyframes showPopup {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}